/** @format */
import ktx_logo from '../assets/ktx_logo.png';

const BodyHome: React.FC = () => {
	return (
		<div className='relative isolate overflow-hidden bg-white px-6 pb-10 sm:pb-12 pt-12 sm:pt-16 lg:px-8'>
			<div className='flex flex-col mx-auto max-w-2xl items-center'>
				<h2 className='text-4xl font-bold tracking-tight text-slate-700 sm:text-6xl'>
					Kortex.ai
				</h2>
				<img
					className='mt-6 mx-auto h-20 w-auto'
					src={ktx_logo}
					alt='logo'
				/>
				<p className='mt-6 text-lg leading-8 text-slate-700'>
					Automate documentation.
				</p>
				<p className='mt-1 text-lg leading-8 text-slate-700'>
					Ditch the clicks and streamline your workflow.
				</p>
				<p className='mt-6 text-base max-w-xs text-gray-500 text-justify'>
					Free your time and focus on patients, not the EHR: less
					typing, fewer clicks, effortless stress-free documentation.
					Automate your work now.
				</p>
			</div>
		</div>
	);
};

export default BodyHome;
