/** @format */

import React, { useEffect, useRef, useState } from 'react';
import {
	Link,
	useLoaderData,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { clsx } from 'clsx';

import { ChevronDownIcon } from '@heroicons/react/24/outline';

import { client } from '../utils/AxiosClient';
import toast, { Toaster } from 'react-hot-toast';
import { getNoteById } from '../utils/Services';
import { formatRelativeTime, formatMilitaryTime } from '../utils/UtilFuncs';
import useNoteStore from '../utils/NoteStore';
import ListItem from '../components/ListItem';
import EmptyNotes from './EmptyNotes';
import { update } from 'lodash';

// TODO: Extract socket function to separate utility function
// Extract constants for WebSocket URLs
// const SOCKET_AUTH_URL = 'http://127.0.0.1:8000/api/ws/auth/';
// const SOCKET_URL = 'ws://127.0.0.1:8000/ws/notes/';
// const SOCKET_AUTH_URL = 'https://backend.kortex.ai/api/ws/auth/';
// const SOCKET_URL = 'wss://backend.kortex.ai/ws/notes/';
const SOCKET_AUTH_URL = import.meta.env.VITE_SOCKET_AUTH_URL as string;
const SOCKET_URL = import.meta.env.VITE_SOCKET_URL as string;

// Define constants for status values
const SOCKET_STATUS_CONNECTED = 'Connected';
const SOCKET_STATUS_CREATED = 'created';
const SOCKET_STATUS_UPDATED = 'updated';
const SOCKET_STATUS_USER_UPDATED = 'user_updated';

interface Note {
	[key: string]: string | number;
}

// export async function notesLoader({
// 	request,
// 	params,
// }: {
// 	request: Request;
// 	params: { folder: string };
// }) {
// 	const notesUrl = params.folder === 'archive' ? 'notes/archive' : 'notes';
// 	console.log('getting notes at url:', notesUrl);
// 	const response = await getNotes(notesUrl);
// 	console.log('loader response', response);
// 	return response;
// }

const Notes: React.FC = () => {
	const { folder } = useParams<{ folder: string }>();
	const [socket, setSocket] = useState<WebSocket | null>(null);
	const [socketConnected, setSocketConnected] = useState(false);
	const navigate = useNavigate();
	const {
		notes,
		setNotes,
		addNote,
		updateNote,
		activeNoteId,
		setActiveNoteId,
		toggleArchiveForNote,
		deleteNote,
	} = useNoteStore();

	// TODO: fix the activenoteid issue back and forth notes<>archive
	useEffect(() => {
		console.log('folder: ', folder);

		if (notes.length > 0) {
			const filteredNotes = notes.filter((note) =>
				folder === 'archive'
					? note.status === 'ARCHIVED'
					: note.status !== 'ARCHIVED'
			);
			console.log('length of filtered notes', filteredNotes.length);
			if (filteredNotes.length > 0) {
				const firstNoteId = filteredNotes[0].id;
				console.log('inside filtered notes');
				navigate(`/dashboard/${folder}/${firstNoteId}`);
				setActiveNoteId(firstNoteId.toString());
			} else {
				console.log('inside empty notes');
				navigate(`/dashboard/${folder}/empty`);
				setActiveNoteId('');
			}
		}
	}, [folder]);

	// Establish Socket connection
	useEffect(() => {
		const openSocket = async () => {
			try {
				const {
					data: { uuid: uuidToken },
				} = await client.get(SOCKET_AUTH_URL);
				console.log('SOCKET_AUTH_URL:', SOCKET_AUTH_URL);
				console.log('UUID Token:', uuidToken);

				const newSocket = new WebSocket(
					`${SOCKET_URL}?uuid=${uuidToken}`
				);

				newSocket.onerror = function (event) {
					console.error('WebSocket error observed:', event);
				};

				newSocket.onclose = function (event) {
					if (event.wasClean) {
						console.log(
							`Connection closed cleanly, code=${event.code} reason=${event.reason}`
						);
					} else {
						// e.g. server process killed or network down
						console.log('Connection died');
					}
				};

				newSocket.onopen = () => {
					console.log('Connection established successfully'); // Add this line
					setSocket(newSocket);
					setSocketConnected(true);
				};
			} catch (error: any) {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				console.error('Error opening WebSocket:', error);
			}
		};

		if (!socket) {
			openSocket();
		}

		// Clean up the socket connection when the component unmounts
		return () => {
			if (socket) {
				socket.close();
			}
			setSocketConnected(false);
		};
	}, [socket]);

	useEffect(() => {
		if (socket) {
			// Listen for messages from the server
			socket.onmessage = async (event) => {
				try {
					const message = JSON.parse(event.data);
					const { note_id, status } = message.message;

					if (status === SOCKET_STATUS_CONNECTED) {
						console.log('Server connected');
						return;
					}

					if (status === SOCKET_STATUS_CREATED) {
						const newNote = await getNoteById('notes', note_id);
						addNote(newNote);
					} else if (status === SOCKET_STATUS_USER_UPDATED) {
						// TODO: Update status icon on note card
						return;
					} else if (status === SOCKET_STATUS_UPDATED) {
						const updatedNote = await getNoteById('notes', note_id);
						updateNote(updatedNote);
					}
				} catch (error) {
					console.error('Error while parsing socket message:', error);
				}
			};
		}
	}, [socket]);

	const filterAndSortNotes = (notes: Note[]) => {
		return notes
			.filter((note) =>
				folder === 'archive'
					? note.status === 'ARCHIVED'
					: note.status !== 'ARCHIVED'
			)
			.sort(
				(a, b) =>
					new Date(b.created).getTime() -
					new Date(a.created).getTime()
			);
	};

	const navigateNextNote = (filteredNotes: Note[], noteIndex: number) => {
		const nextNoteIndex = noteIndex + 1;
		const previousNoteIndex = noteIndex - 1;

		if (nextNoteIndex < filteredNotes.length) {
			const nextId = filteredNotes[nextNoteIndex].id.toString();
			navigate(`/dashboard/${folder}/${nextId}`);
		} else if (previousNoteIndex >= 0) {
			const previousId = filteredNotes[previousNoteIndex].id.toString();
			navigate(`/dashboard/${folder}/${previousId}`);
		} else {
			navigate(`/dashboard/${folder}/empty`);
			setActiveNoteId('');
		}
	};

	const handleArchiveNote = async (noteId: string) => {
		const filteredNotes = filterAndSortNotes(notes);
		const noteIndex = filteredNotes.findIndex((note) => note.id === noteId);
		const note = filteredNotes[noteIndex];
		await toggleArchiveForNote(noteId, note.status.toString());
		if (filteredNotes.length > 0 && noteIndex !== -1) {
			navigateNextNote(filteredNotes, noteIndex);
		}
		const msg =
			note.status.toString() === 'ARCHIVED'
				? 'Moved to Notes'
				: 'Note Archived';

		toast.success(msg, {
			duration: 1000,
			position: 'top-center',
		});
	};

	const handleDeleteNote = async (noteId: string) => {
		const filteredNotes = filterAndSortNotes(notes);
		const noteIndex = filteredNotes.findIndex((note) => note.id === noteId);

		if (filteredNotes.length > 0 && noteIndex !== -1) {
			navigateNextNote(filteredNotes, noteIndex);
		}
		await deleteNote(noteId);
		toast.success('Note Deleted!', {
			duration: 1000,
			position: 'top-center',
		});
	};

	const toggleListModalVisibility = () => {
		setIsListModalVisible((prevState) => !prevState);
	};

	const basisLRef = useRef<HTMLDivElement>(null);

	const [isListModalVisible, setIsListModalVisible] = useState(false);

	return (
		<div id='notes' className='flex sm:flex-row flex-col'>
			<div
				className='flex flex-row mt-4 ml-5 mb-2 sm:hidden cursor-pointer'
				onClick={() => toggleListModalVisibility()}>
				<span className='text-xs mr-1 sm:hidden uppercase'>
					{`${folder === 'archive' ? 'Archived Notes' : 'Notes'}`}{' '}
				</span>
				<ChevronDownIcon className='h-5 w-5 sm:hidden cursor-pointer' />
			</div>

			<div
				id='basisL'
				ref={basisLRef}
				className='basis-1/5 hidden mobile:mx-2 inset-x-0 bg-white sm:block'>
				<ul role='list' className='space-y-3 m-12 sm:m-0'>
					<li id='patient-list-header'>
						<div className='m:px-0'>
							<h3 className='text-sm font-semibold leading-7 ml-2 uppercase'>
								Patients
							</h3>
						</div>
						<div className='mt-1 border-t border-gray-300 divide-y divide-gray-500'></div>
					</li>
					{notes
						.filter((note) =>
							folder === 'archive'
								? note.status === 'ARCHIVED'
								: note.status !== 'ARCHIVED'
						)
						.sort(
							(a, b) =>
								new Date(b.created).getTime() -
								new Date(a.created).getTime()
						)
						.map((note) => (
							<ListItem key={note.id} note={note} />
						))}
				</ul>
			</div>

			<Dialog
				className='z-200'
				open={isListModalVisible}
				onClose={() => setIsListModalVisible(false)}>
				<div className='fixed inset-0 flex h-screen items-start justify-center p-4 bg-black/30 z-60 overflow-y-auto'>
					<Dialog.Panel>
						<div
							id='list-modal'
							className='bg-white rounded-xl p-1 shadow-inner m-5'>
							<ul role='list' className='space-y-3 m-5 sm:m-0'>
								{notes
									.filter((note) =>
										folder === 'archive'
											? note.status === 'ARCHIVED'
											: note.status !== 'ARCHIVED'
									)
									.sort(
										(a, b) =>
											new Date(b.created).getTime() -
											new Date(a.created).getTime()
									)
									.map((note) => (
										<ListItem
											key={note.id}
											note={note}
											onSmallScreenSelect={() =>
												setIsListModalVisible(false)
											}
										/>
									))}
							</ul>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>

			<div id='basisR' className='basis-4/5 mx-4 relative'>
				{notes.length > 0 ? (
					<Outlet
						context={{
							handleDeleteNote,
							handleArchiveNote,
						}}
					/>
				) : (
					<EmptyNotes />
				)}

				<div className='flex flex-row ml-[10px] justify-items-center text-xs absolute top-0 right-0 -mt-6 mobile:-mt-5 justify-end'>
					Connected
					{socket ? (
						<div className='h-[10px] w-[10px] mx-2 bg-green-500 shadow-lg rounded-full ring-2 ring-green-200' />
					) : (
						<div className='h-[10px] w-[10px] mx-2 bg-red-500 shadow-xl rounded-full ring-2 ring-red-200' />
					)}
				</div>
			</div>
		</div>
	);
};

export default Notes;
