/** @format */

import { NavLink, useLocation } from 'react-router-dom';
import useNoteStore from '../utils/NoteStore';

type Props = {};

const commonLinkClass =
	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold';

const navLinkClassActive = 'text-blue-400';

const navLinkClassPending = 'text-blue-200';

const navLinkClassInactive = 'hover:text-blue-400';

interface NavLinkItemProps {
	to: string;
	icon: React.ElementType;
	name: string;
}

const SideBarNavLink = ({ to, icon: Icon, name }: NavLinkItemProps) => {
	const location = useLocation();
	const { activeNoteId } = useNoteStore();

	return (
		<NavLink
			to={to}
			className={({ isActive, isPending }) => {
				if (isActive) {
					return `${commonLinkClass} ${navLinkClassActive}`;
				} else if (isPending) {
					return `${commonLinkClass} ${navLinkClassPending}`;
				} else {
					return `${commonLinkClass} ${navLinkClassInactive}`;
				}
			}}
			onClick={(e) => {
				if (location.pathname.includes(to)) {
					e.preventDefault();
				}
			}}>
			<Icon className='h-6 w-6 shrink-0' aria-hidden='true' />
			{name}
		</NavLink>
	);
};

export default SideBarNavLink;
