/** @format */

import {
	ChartBarSquareIcon,
	Cog6ToothIcon,
	FolderIcon,
	GlobeAltIcon,
	ServerIcon,
	SignalIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
	Link,
	Button,
	Element,
	Events,
	animateScroll as scroll,
	scrollSpy,
} from 'react-scroll';

const secondaryNavigation = [
	{ name: 'Account', href: '#', current: true },
	{ name: 'Notifications', href: '#', current: false },
	{ name: 'Billing', href: '#', current: false },
	{ name: 'Teams', href: '#', current: false },
	{ name: 'Integrations', href: '#', current: false },
];

export default function Settings() {
	return (
		<>
			<div className='bg-white'>
				<main>
					<header className='border-b border-gray-300'>
						{/* Secondary navigation */}
						<nav className='flex overflow-x-auto py-4'>
							<ul
								role='list'
								className='flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-600 sm:px-6 lg:px-8'>
								{secondaryNavigation.map((item) => (
									<li key={item.name}>
										<Link
											to={item.href}
											className={
												item.current
													? 'text-indigo-500'
													: 'text-gray-400'
											}>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						</nav>
					</header>

					{/* Settings forms */}
					<div className='divide-gray-300'>
						<div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
							<div>
								<h2 className='text-base font-semibold leading-7 text-gray-600'>
									Personal Information
								</h2>
								<p className='mt-1 text-sm leading-6 text-gray-400'>
									Use a permanent address where you can
									receive mail.
								</p>
							</div>

							<form className='md:col-span-2'>
								<div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
									<div className='col-span-full flex items-center gap-x-8'>
										<img
											src='https://images.unsplash.com/photo-1511367461989-f85a21fda167?q=80&w=2662&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
											alt=''
											className='h-24 w-24 flex-none rounded-lg bg-gray-300 object-cover'
										/>
										<div>
											<button
												type='button'
												className='rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-white'>
												Change avatar
											</button>
											<p className='mt-2 text-xs leading-5 text-gray-400'>
												JPG, GIF or PNG. 1MB max.
											</p>
										</div>
									</div>

									<div className='sm:col-span-3'>
										<label
											htmlFor='first-name'
											className='block text-sm font-medium leading-6 text-gray-700'>
											First name
										</label>
										<div className='mt-2'>
											<input
												type='text'
												name='first-name'
												id='first-name'
												autoComplete='given-name'
												className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
											/>
										</div>
									</div>

									<div className='sm:col-span-3'>
										<label
											htmlFor='last-name'
											className='block text-sm font-medium leading-6 text-gray-700'>
											Last name
										</label>
										<div className='mt-2'>
											<input
												type='text'
												name='last-name'
												id='last-name'
												autoComplete='family-name'
												className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
											/>
										</div>
									</div>

									<div className='col-span-full'>
										<label
											htmlFor='email'
											className='block text-sm font-medium leading-6 text-gray-700'>
											Email address
										</label>
										<div className='mt-2'>
											<input
												id='email'
												name='email'
												type='email'
												autoComplete='email'
												className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
											/>
										</div>
									</div>

									<div className='col-span-full'>
										<label
											htmlFor='username'
											className='block text-sm font-medium leading-6 text-gray-700'>
											Username
										</label>
										<div className='mt-2'>
											<div className='flex rounded-md bg-gray-100 ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'>
												<span className='flex select-none items-center pl-3 text-gray-600 sm:text-sm'>
													kortex.ai/
													<span
														className='inline-block'
														style={{
															width: '0.25rem',
														}}></span>
												</span>
												<input
													type='text'
													name='username'
													id='username'
													autoComplete='username'
													className='flex-1 border border-gray-300 bg-transparent py-1.5 pl-1 text-gray-700 focus:ring-0 sm:text-sm sm:leading-6'
													placeholder='username'
												/>
											</div>
										</div>
									</div>

									<div className='col-span-full'>
										<label
											htmlFor='timezone'
											className='block text-sm font-medium leading-6 text-gray-700'>
											Timezone
										</label>
										<div className='mt-2'>
											<select
												id='timezone'
												name='timezone'
												className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'>
												<option>
													Pacific Standard Time
												</option>
												<option>
													Eastern Standard Time
												</option>
												<option>
													Greenwich Mean Time
												</option>
											</select>
										</div>
									</div>
								</div>

								<div className='mt-8 flex'>
									<button
										type='submit'
										className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
										Save
									</button>
								</div>
							</form>
						</div>

						<div className='bg-white'>
							<main>
								{/* ... (header section) */}

								{/* Settings forms */}
								<div className='divide-gray-300'>
									{/* Change password */}
									<div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
										<div>
											<h2 className='text-base font-semibold leading-7 text-gray-600'>
												Change password
											</h2>
											<p className='mt-1 text-sm leading-6 text-gray-400'>
												Update your password associated
												with your account.
											</p>
										</div>

										<form className='md:col-span-2'>
											<div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
												<div className='col-span-full'>
													<label
														htmlFor='current-password'
														className='block text-sm font-medium leading-6 text-gray-700'>
														Current password
													</label>
													<div className='mt-2'>
														<input
															id='current-password'
															name='current_password'
															type='password'
															autoComplete='current-password'
															className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
														/>
													</div>
												</div>

												<div className='col-span-full'>
													<label
														htmlFor='new-password'
														className='block text-sm font-medium leading-6 text-gray-700'>
														New password
													</label>
													<div className='mt-2'>
														<input
															id='new-password'
															name='new_password'
															type='password'
															autoComplete='new-password'
															className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
														/>
													</div>
												</div>

												<div className='col-span-full'>
													<label
														htmlFor='confirm-password'
														className='block text-sm font-medium leading-6 text-gray-700'>
														Confirm password
													</label>
													<div className='mt-2'>
														<input
															id='confirm-password'
															name='confirm_password'
															type='password'
															autoComplete='new-password'
															className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
														/>
													</div>
												</div>
											</div>

											<div className='mt-8 flex'>
												<button
													type='submit'
													className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
													Save
												</button>
											</div>
										</form>
									</div>

									{/* Log out other sessions */}
									<div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
										<div>
											<h2 className='text-base font-semibold leading-7 text-gray-600'>
												Log out other sessions
											</h2>
											<p className='mt-1 text-sm leading-6 text-gray-400'>
												Please enter your password to
												confirm you would like to log
												out of your other sessions
												across all of your devices.
											</p>
										</div>

										<form className='md:col-span-2'>
											<div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
												<div className='col-span-full'>
													<label
														htmlFor='logout-password'
														className='block text-sm font-medium leading-6 text-gray-700'>
														Your password
													</label>
													<div className='mt-2'>
														<input
															id='logout-password'
															name='password'
															type='password'
															autoComplete='current-password'
															className='block w-full rounded-md border border-gray-300 py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6'
														/>
													</div>
												</div>
											</div>

											<div className='mt-8 flex'>
												<button
													type='submit'
													className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'>
													Log out other sessions
												</button>
											</div>
										</form>
									</div>

									{/* Delete account */}
									<div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
										<div>
											<h2 className='text-base font-semibold leading-7 text-gray-600'>
												Delete account
											</h2>
											<p className='mt-1 text-sm leading-6 text-gray-400'>
												No longer want to use our
												service? You can delete your
												account here. This action is not
												reversible. All information
												related to this account will be
												deleted permanently.
											</p>
										</div>

										<form className='flex items-start md:col-span-2'>
											<button
												type='submit'
												className='rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400'>
												Yes, delete my account
											</button>
										</form>
									</div>
								</div>
							</main>
						</div>
					</div>
				</main>
			</div>
		</>
	);
}
