/** @format */
import React from 'react';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import HomeLanding from './pages/HomeLanding';
import Dashboard, { notesLoader } from './pages/Dashboard';
import NoMatch from './pages/NoMatch';
import { SignIn } from './pages/SignIn';
import { action as actionSignIn } from './pages/SignIn';
// import Notes, { notesLoader } from './pages/Notes';
// import Note, { noteLoader } from './pages/Note';
import Notes from './pages/Notes';
import Note from './pages/Note';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import { AuthRequired } from './utils/AuthRequired';
import NotesLayout from './layouts/NotesLayout';
import EmptyNotes from './pages/EmptyNotes';
import { getNotes } from './utils/Services';
import ErrorElement from './pages/Error';

if (!Sentry.getCurrentHub().getClient()) {
	Sentry.init({
		dsn: 'https://e9c84c669087ec3729a758f84e1229fd@o251216.ingest.sentry.io/4506478140784640',
		integrations: [
			// new Sentry.BrowserTracing({
			// 	routingInstrumentation: Sentry.reactRouterV6Instrumentation(
			// 		React.useEffect,
			// 		useLocation,
			// 		useNavigationType,
			// 		createRoutesFromChildren,
			// 		matchRoutes
			// 	),
			// 	tracePropagationTargets: [
			// 		'localhost',
			// 		/^https:\/\/backend\.kortex\.ai\/api/,
			// 		/^wss:\/\/backend\.kortex\.ai\/ws/,
			// 	],
			// }),
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
			}),
			new Sentry.Feedback({
				colorScheme: 'light',
				formTitle: 'Send Feedback',
				namePlaceholder: '',
				emailPlaceholder: '',
				messagePlaceholder: 'Please enter your feedback',
				submitButtonLabel: 'Submit Feedback',
				showBranding: false,
				buttonLabel: 'Send Feedback',
				useSentryUser: {
					email: 'email',
					name: 'name',
				},
			}),
		],
		// TODO: Add this to .env, to easily change
		// Performance Monitoring
		tracesSampleRate: 0.05, //  Capture a percentage % of the transactions, 1=100%
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const sentryCreateBrowserRouter =
	Sentry.wrapCreateBrowserRouter(createBrowserRouter);

let router = sentryCreateBrowserRouter(
	createRoutesFromElements([
		<Route path='/' element={<HomeLanding />} />,
		<Route element={<AuthRequired />}>
			<Route
				path='dashboard'
				element={<Dashboard />}
				loader={notesLoader}>
				<Route
					path=':folder'
					id='folder'
					element={<Notes />}
					// @ts-ignore
					// loader={notesLoader}
					errorElement={<ErrorElement />}>
					<Route
						path=':noteId'
						element={<Note />}
						// @ts-ignore
						// loader={noteLoader}
						errorElement={<ErrorElement />}
					/>
					<Route path='empty' element={<EmptyNotes />} />
				</Route>
				<Route path='*' element={<NoMatch />} />

				<Route
					path='templates'
					element={<h2>Templates coming soon!</h2>}
				/>
				<Route path='calendar' element={<h2>Calendar</h2>} />
				<Route path='documents' element={<h2>Documents</h2>} />
				<Route path='reports' element={<Reports />} />

				<Route path='GAEP' element={<h2>GAEP</h2>} />
				<Route
					path='glendale-adventist'
					element={<h2>Glendale Adventist</h2>}
				/>
				<Route path='emref' element={<h2>Emref</h2>} />
				<Route path='settings' element={<Settings />} />
				<Route path='*' element={<NoMatch />} />
			</Route>
		</Route>,
		<Route path='signin' action={actionSignIn} element={<SignIn />} />,
		// <Route path='login' action={actionLogIn} element={<LogIn />} />,
		<Route path='*' element={<NoMatch />} />,
	])
);

export default function App() {
	return <RouterProvider router={router} />;
}

if (import.meta.hot) {
	import.meta.hot.dispose(() => router.dispose());
}
