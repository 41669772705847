/** @format */

import React, { ReactNode } from 'react';

interface NoteSectionProps {
	title: string;
	children: ReactNode;
	className?: string;
}

const NoteSection: React.FC<NoteSectionProps> = ({
	title,
	children,
	className = '',
}) => {
	const id = title.toLowerCase().replace(/ /g, '-');
	return (
		<div
			id={`note-section-${id}`}
			className={`px-4 py-2 sm:grid sm:grid-cols-1 grid-cols-2 sm:gap-4 sm:px-6 ${className}`}>
			<div className='px-3 text-sm uppercase font-bold'>
				<span>{title}</span>
			</div>
			<div className='mt-1 text-sm sm:text-xs sm:col-span-4 sm:mt-0'>
				{children}
			</div>
		</div>
	);
};

export default NoteSection;
