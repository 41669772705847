/** @format */
import { Outlet, Link, NavLink, useLoaderData } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useAuthStore } from '../utils/UseAuthStore';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
	ArchiveBoxIcon,
	Bars3Icon,
	BellIcon,
	CalendarIcon,
	ChartPieIcon,
	Cog6ToothIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	HomeIcon,
	UsersIcon,
	XMarkIcon,
	PencilSquareIcon,
} from '@heroicons/react/24/outline';
import {
	ChevronDownIcon,
	MagnifyingGlassIcon,
} from '@heroicons/react/20/solid';
import SideBarNavLink from './../components/SideBarNavLink';
import ktx_logo_zinc from '../assets/ktx_logo_zinc.png';
import { getNotes } from '../utils/Services';
import useNoteStore from '../utils/NoteStore';
import { Note } from '../utils/Types';
import EmptyNotes from './EmptyNotes';

const navigation = [
	// {
	// 	name: 'Dashboard',
	// 	href: 'dash',
	// 	icon: HomeIcon,
	// },
	{ name: 'Notes', href: 'notes', icon: PencilSquareIcon },
	{ name: 'Archive', href: 'archive', icon: ArchiveBoxIcon },
	{ name: 'Templates', href: 'templates', icon: PencilSquareIcon },
	{ name: 'Reports', href: 'reports', icon: ChartPieIcon },
];
const teams = [
	{ id: 1, name: 'GAEP', href: 'gaep', initial: 'G', current: false },
	{
		id: 2,
		name: 'HSHS',
		href: 'hshs-sj',
		initial: 'SJ',
		current: false,
	},
	// { id: 3, name: 'EmRef', href: 'emref', initial: 'E', current: false },
];
const userNavigation = [{ name: 'Sign out', href: '#', id: 'signout' }];

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export async function notesLoader() {
	const response = await getNotes('notes');
	return response;
}

const Dashboard: React.FC = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const logout = useAuthStore((state) => state.logout);
	const username = useAuthStore((state) => state.username);
	const [searchOpen, setSearchOpen] = useState(false);
	const { notes, setNotes } = useNoteStore();
	const fetchedNotes = useLoaderData() as Note[];

	useEffect(() => {
		console.log('fetching notes');
		setNotes(fetchedNotes);
		console.log('fetched notes', fetchedNotes);
	}, []);

	const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setSearchOpen(true);
	};

	function closeModal() {
		setSearchOpen(false);
	}

	function openModal() {
		setSearchOpen(true);
	}

	return (
		<>
			<div className='text-slate-800 font-inter'>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as='div'
						className='relative z-50 lg:hidden'
						onClose={setSidebarOpen}>
						<Transition.Child
							as={Fragment}
							enter='transition-opacity ease-linear duration-300'
							enterFrom='opacity-0'
							enterTo='opacity-100'
							leave='transition-opacity ease-linear duration-300'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'>
							<div className='fixed inset-0 bg-white' />
						</Transition.Child>

						<div className='fixed inset-0 flex'>
							<Transition.Child
								as={Fragment}
								enter='transition ease-in-out duration-300 transform'
								enterFrom='-translate-x-full'
								enterTo='translate-x-0'
								leave='transition ease-in-out duration-300 transform'
								leaveFrom='translate-x-0'
								leaveTo='-translate-x-full'>
								<Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
									<Transition.Child
										as={Fragment}
										enter='ease-in-out duration-300'
										enterFrom='opacity-0'
										enterTo='opacity-100'
										leave='ease-in-out duration-300'
										leaveFrom='opacity-100'
										leaveTo='opacity-0'>
										<div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
											<button
												type='button'
												className='-m-2.5 p-2.5'
												onClick={() =>
													setSidebarOpen(false)
												}>
												<span className='sr-only'>
													Close sidebar
												</span>
												<XMarkIcon
													className='h-6 w-6 text-white'
													aria-hidden='true'
												/>
											</button>
										</div>
									</Transition.Child>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-50 px-6 pb-4 ring-1 ring-white/10'>
										<div className='flex h-16 shrink-0 items-center'>
											<img
												className='h-8 w-auto'
												src={ktx_logo_zinc}
												alt='Kortex.ai'
											/>{' '}
											Kortex.ai
										</div>
										<nav className='flex flex-1 flex-col'>
											<ul
												role='list'
												className='flex flex-1 flex-col gap-y-7'>
												<li>
													<ul
														role='list'
														className='-mx-2 space-y-1'>
														{navigation.map(
															(item) => (
																<li
																	key={
																		item.name
																	}
																	onClick={() =>
																		setSidebarOpen(
																			false
																		)
																	}>
																	<SideBarNavLink
																		to={
																			item.href
																		}
																		icon={
																			item.icon
																		}
																		name={
																			item.name
																		}
																	/>
																</li>
															)
														)}
													</ul>
												</li>
												<li>
													<div className='text-xs font-semibold leading-6'>
														Your teams
													</div>
													<ul
														role='list'
														className='-mx-2 mt-2 space-y-1'>
														{teams.map((team) => (
															<li key={team.name}>
																<a
																	href={
																		team.href
																	}
																	className={classNames(
																		team.current
																			? 'bg-gray-800 text-white'
																			: 'text-gray-400 hover:text-white hover:bg-gray-800',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
																	)}>
																	<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white'>
																		{
																			team.initial
																		}
																	</span>
																	<span className='truncate'>
																		{
																			team.name
																		}
																	</span>
																</a>
															</li>
														))}
													</ul>
												</li>
												<li className='mt-auto'>
													<Link
														to='/settings'
														className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-gray-800 hover:text-white'>
														<Cog6ToothIcon
															className='h-6 w-6 shrink-0'
															aria-hidden='true'
														/>
														Settings
													</Link>
												</li>
											</ul>
										</nav>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col'>
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-50 px-6 pb-4'>
						<div className='flex h-16 shrink-0 items-center'>
							<img
								className='h-12 w-auto'
								src={ktx_logo_zinc}
								alt='Kortex'
							/>
							Kortex.ai
						</div>
						<nav className='flex flex-1 flex-col'>
							<ul
								role='list'
								className='flex flex-1 flex-col gap-y-7'>
								<li>
									<ul role='list' className='-mx-2 space-y-1'>
										{navigation.map((item) => (
											<li
												key={item.name}
												onClick={() =>
													setSidebarOpen(false)
												}>
												<SideBarNavLink
													to={item.href}
													icon={item.icon}
													name={item.name}
												/>
											</li>
										))}
									</ul>
								</li>
								<li>
									<div className='text-xs font-semibold leading-6'>
										Your teams
									</div>
									<ul
										role='list'
										className='-mx-2 mt-2 space-y-1'>
										{teams.map((team) => (
											<li key={team.name}>
												<Link
													to={team.href}
													className={classNames(
														team.current
															? 'text-blue-400'
															: 'hover:text-blue-400',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
													)}>
													<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border hover:bg-blue-300 border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-white group-hover:text-white'>
														{team.initial}
													</span>
													<span className='truncate'>
														{team.name}
													</span>
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className='mt-auto'>
									<Link
										to='settings'
										className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:text-blue-400'>
										<Cog6ToothIcon
											className='h-6 w-6 shrink-0'
											aria-hidden='true'
										/>
										Settings
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</div>

				<div className='lg:pl-48'>
					<div className='top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
						<button
							type='button'
							className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
							onClick={() => setSidebarOpen(true)}>
							<span className='sr-only'>Open sidebar</span>
							<Bars3Icon className='h-6 w-6' aria-hidden='true' />
						</button>

						{/* Separator */}
						<div
							className='h-4 w-px bg-gray-900/10 lg:hidden'
							aria-hidden='true'
						/>

						<div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
							<form
								onSubmit={handleSubmitSearch}
								className='relative flex flex-1'>
								<label
									htmlFor='search-field'
									className='sr-only'>
									Search
								</label>
								<MagnifyingGlassIcon
									className='pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400'
									aria-hidden='true'
								/>
								<input
									id='search-field'
									className='block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm'
									placeholder='Search...'
									type='search'
									name='search'
								/>
							</form>
							{/* TODO: Working Here */}

							<div className='flex items-center gap-x-4 lg:gap-x-6'>
								{/* Separator */}
								<div
									className='hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10'
									aria-hidden='true'
								/>

								{/* Profile dropdown */}
								<Menu as='div' className='relative'>
									<Menu.Button className='-m-1.5 flex items-center p-1.5'>
										<span className='sr-only'>
											Open user menu
										</span>
										<img
											className='h-8 w-8 rounded-full bg-gray-50'
											src='https://images.unsplash.com/photo-1504715329877-f69f5258f4e8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80'
											alt=''
										/>
										<span className='hidden lg:flex lg:items-center'>
											<span
												className='ml-4 text-sm font-semibold leading-6'
												aria-hidden='true'>
												{username}
											</span>
											<ChevronDownIcon
												className='ml-2 h-5 w-5 text-gray-400'
												aria-hidden='true'
											/>
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter='transition ease-out duration-100'
										enterFrom='transform opacity-0 scale-95'
										enterTo='transform opacity-100 scale-100'
										leave='transition ease-in duration-75'
										leaveFrom='transform opacity-100 scale-100'
										leaveTo='transform opacity-0 scale-95'>
										<Menu.Items className='absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none'>
											{userNavigation.map((item) => (
												<Menu.Item key={item.name}>
													{({ active }) => (
														<a
															href={item.href}
															onClick={
																item.id ===
																'signout'
																	? logout
																	: undefined
															}
															className={classNames(
																active
																	? 'text-blue-400'
																	: '',
																'block px-3 py-1 text-sm leading-6 hover:text-blue-400'
															)}>
															{item.name}
														</a>
													)}
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>

					<main className='pt-2 sm:pt-8 pb-2'>
						<div id='outlet' className='sm:px-6 lg:px-8'>
							<Outlet />
							{/* {notes.length > 0 ? (
								<Outlet />
							) : (
								<div>Loading...</div>
							)} */}
						</div>
					</main>
				</div>
			</div>

			<Transition appear show={searchOpen} as={Fragment}>
				<Dialog as='div' className='z-200' onClose={closeModal}>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto z-70'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
									<Dialog.Title
										as='h3'
										className='text-lg font-medium leading-6 text-gray-900'>
										Search successful
									</Dialog.Title>
									<div className='mt-2'>
										<p className='text-sm text-gray-500'>
											Your search submitted.
										</p>
									</div>

									<div className='mt-4'>
										<button
											type='button'
											className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
											onClick={closeModal}>
											Got it, thanks!
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
			{/* <Dialog
				open={searchOpen}
				onClose={() => setSearchOpen(false)}
				className='z-100'>
				<Dialog.Panel>
					<Dialog.Title className='text-lg font-medium text-gray-900'>
						Search Results
					</Dialog.Title>
					<Dialog.Description>Search Results</Dialog.Description>
					<p>Contents of search</p>
					<button type='button' onClick={() => setSearchOpen(false)}>
						Save
					</button>
					<button type='button' onClick={() => setSearchOpen(false)}>
						Close
					</button>
				</Dialog.Panel>
			</Dialog> */}
		</>
	);
};

export default Dashboard;
