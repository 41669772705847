/** @format */

import React, { FC } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { Form, useActionData, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../utils/UseAuthStore';
import { login, register } from '../utils/Services';
import ktx_logo from '../assets/ktx_logo.png';

interface Tokens {
	accessToken: string;
	refreshToken: string;
}

interface ActionData {
	error: JSX.Element;
	tokens: {
		accessToken: string;
		refreshToken: string;
	};
}

interface ActionResponse {
	tokens: Tokens | null;
	error: string | null;
}

interface LoginData {
	email: string;
	password: string;
}

export const action = async ({ request }: any): Promise<ActionResponse> => {
	try {
		const formData = await request.formData();

		// const formData = new FormData(await request.clone().text());
		const type = formData.get('type');
		const email = formData.get('email');
		const password = formData.get('password');
		const response =
			type === 'register'
				? await register({ email, password })
				: await login({ email, password });
		let { access, refresh } = response.data;
		let accessToken = access;
		let refreshToken = refresh;
		return { tokens: { accessToken, refreshToken }, error: null };
	} catch (error: any) {
		return {
			error: error?.response?.data?.message || error.message,
			tokens: null,
		};
	}
};

export const SignIn: React.FC = () => {
	const actionData = useActionData() as ActionData;
	const navigate = useNavigate();
	const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
	const login = useAuthStore((state) => state.login);

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/dashboard/notes');
		} else if (actionData?.tokens) {
			login(actionData.tokens);
			navigate('/dashboard/notes');
		}
	}, [isLoggedIn, actionData, login, navigate]);

	return (
		<>
			<div className='flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8 bg-white'>
				<div className='w-full max-w-md space-y-8'>
					<div>
						<img
							className='mx-auto h-12 w-auto'
							src={ktx_logo}
							alt='Your Company'
						/>
						<h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-slate-800'>
							Sign in
						</h2>
						<p className='mt-2 text-center text-sm text-gray-600'>
							Or{' '}
							<a
								href='#'
								className='font-medium text-indigo-600 hover:text-indigo-500'>
								start your free trial
							</a>
						</p>
					</div>
					{actionData?.error && (
						<div className='alert'>{actionData?.error}</div>
					)}
					<Form className='mt-8 space-y-6' method='post'>
						<input
							type='hidden'
							name='remember'
							defaultValue='true'
						/>
						<div className='-space-y-px rounded-md shadow-sm'>
							<div>
								<label
									htmlFor='email-address'
									className='sr-only'>
									Email address
								</label>
								<input
									id='email'
									name='email'
									type='text'
									autoComplete='text'
									required
									className='relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									placeholder='Email'
								/>
								{/* TODO: remove value */}
							</div>
							<div>
								<label htmlFor='password' className='sr-only'>
									Password
								</label>
								<input
									id='password'
									name='password'
									type='password'
									autoComplete='current-password'
									required
									className='relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
									placeholder='Password'
								/>
								{/* TODO: remove value */}
							</div>
						</div>

						<div className='flex items-center justify-between'>
							<div className='flex items-center'>
								<input
									id='remember-me'
									name='remember-me'
									type='checkbox'
									className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
								/>
								<label
									htmlFor='remember-me'
									className='ml-2 block text-sm text-gray-900'>
									Remember me
								</label>
							</div>

							<div className='text-sm'>
								<a
									href='#'
									className='font-medium text-indigo-600 hover:text-indigo-500'>
									Forgot your password?
								</a>
							</div>
						</div>

						<div>
							<button
								type='submit'
								className='group relative flex w-full justify-center rounded-md bg-slate-500 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
								<span className='absolute inset-y-0 left-0 flex items-center pl-3'>
									<LockClosedIcon
										className='h-5 w-5 text-white group-hover:text-white'
										aria-hidden='true'
									/>
								</span>
								Sign in
							</button>
						</div>
					</Form>
				</div>
			</div>
		</>
	);
};

// export default SignIn;
