/** @format */

import { Link } from 'react-router-dom';

const link_css =
	'rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white';

const NoMatch: React.FC = () => {
	return (
		<div className='bg gray-400 flex justify-center items-center h-screen'>
			<h1 className=''>404</h1>
			<Link to='dashboard' className={link_css}>
				Back
			</Link>
		</div>
	);
};

export default NoMatch;
