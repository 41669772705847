/** @format */

import React, { forwardRef } from 'react';

interface EditableDivProps {
	content: any;
	className?: string;
	onBlur: () => void;
	id: string;
	focusOutline?: boolean;
}

const EditableDiv = forwardRef<HTMLDivElement, EditableDivProps>(
	({ content, className, onBlur, id, focusOutline }, ref) => {
		const defaultClassName =
			'p-3 text-base outline-none text-balance rounded-lg border hover:border-blue-300';
		let fullClassName = className
			? `${defaultClassName} ${className}`
			: defaultClassName;

		if (focusOutline) {
			fullClassName = fullClassName.concat(
				' focus:ring-1 focus:ring-blue-200 focus:border-blue-200 focus:shadow-lg'
			);
		}

		return (
			<div
				id={id}
				className={fullClassName}
				contentEditable
				suppressContentEditableWarning
				onBlur={onBlur}
				ref={ref}>
				{content}
			</div>
		);
	}
);

export default EditableDiv;
