/** @format */

import { AxiosResponse } from 'axios';
import { client } from './AxiosClient';

interface RegisterData {
	email: string;
	password: string;
}

interface Note {
	[key: string]: string | number;
}

interface LoginData {
	email: string;
	password: string;
}

interface UserProfile {
	// Define the properties of a user profile object.
}

export function register({
	email,
	password,
}: RegisterData): Promise<AxiosResponse> {
	return client.post(
		'api/register/',
		{ email, password },
		{ headers: { authorization: false } }
	);
}

export function login({ email, password }: LoginData): Promise<AxiosResponse> {
	return client.post(
		'api/login/',
		{ email, password },
		{ headers: { authorization: false } }
	);
}

export function getProfile(): Promise<AxiosResponse<UserProfile>> {
	return client.get('/users/profile');
}

export const getNotes = async (endpoint: string): Promise<Note[]> => {
	const url = `/api/${endpoint}`;
	const response = await client.get<Note[]>(url);
	return response.data;
};

export const getNoteById = async (
	endpoint: string,
	noteId: string
): Promise<Note> => {
	const url = `/api/${endpoint}/${noteId}`;
	const response = await client.get<Note>(url);
	return response.data;
};

export const getArchivedNoteById = async (
	endpoint: string,
	noteId: string,
	single_endpoint: string
): Promise<Note> => {
	const url = `/api/${endpoint}/${noteId}/${single_endpoint}`;
	const response = await client.get<Note>(url);
	return response.data;
};

export const updateNoteFieldById = async (
	endpoint: string,
	noteId: string,
	data: object
): Promise<Note> => {
	const url = `/api/${endpoint}/${noteId}/`;
	try {
		const response = await client.patch<Note>(url, data);
		console.log(response);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const deleteNoteByID = async (
	endpoint: string,
	noteId: string
): Promise<Note> => {
	const url = `/api/${endpoint}/${noteId}`;
	const response = await client.delete<Note>(url);
	return response.data;
};
