/** @format */

export function formatRelativeTime(timestamp: Date) {
	const now = new Date();
	const diff = now.getTime() - timestamp.getTime();

	if (diff < 60000) {
		return 'secs ago';
	} else if (diff < 3600000) {
		const minutes = Math.floor(diff / 60000);
		return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
	} else if (diff < 86400000) {
		const hours = Math.floor(diff / 3600000);
		return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
	} else {
		return timestamp.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
		});
	}
}

export function formatMilitaryTime(timestamp: Date) {
	const now = new Date();
	const diff = now.getTime() - timestamp.getTime();

	if (diff > 86400000) {
		return timestamp.toLocaleDateString('en-US', {
			month: 'short',
			day: 'numeric',
		});
	}
	const hours = String(timestamp.getHours()).padStart(2, '0');
	const minutes = String(timestamp.getMinutes()).padStart(2, '0');
	const formattedTime = `${hours}:${minutes}`;
	return formattedTime;
}

export function formatCreationTime(timestamp: Date) {
	const hours = String(timestamp.getHours()).padStart(2, '0');
	const minutes = String(timestamp.getMinutes()).padStart(2, '0');
	const month = String(timestamp.getMonth() + 1).padStart(2, '0');
	const day = String(timestamp.getDate()).padStart(2, '0');
	const year = String(timestamp.getFullYear());

	const formattedTime = `${month}/${day}/${year} - ${hours}:${minutes}`;
	return formattedTime;
}

export const convertGender = (gender: string): string => {
	switch (gender.toUpperCase()) {
		case 'M':
			return 'male';
		case 'F':
			return 'female';
		case 'O':
			return 'other gender';
		default:
			return 'Unknown';
	}
};
