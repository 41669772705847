/** @format */
import HeaderHome from '../components/HeaderHome';
import BodyHome from '../components/BodyHome';
import FooterHome from '../components/FooterHome';

const HomeLanding: React.FC = () => {
	return (
		<>
			<HeaderHome />
			<BodyHome />
			<FooterHome />
		</>
	);
};

export default HomeLanding;
