/** @format */

const EmptyNotes: React.FC = () => {
	return (
		<div id='top-note-div'>
			<div id='note-editor-div' className='border-2 shadow-xl rounded-xl'>
				<div className='m-5 flex justify-between items-center'>
					Choose a Note
				</div>
			</div>
		</div>
	);
};

export default EmptyNotes;
