/** @format */

const Report = () => {
	return (
		<div>
			<h1>Reports coming soon!</h1>
			<button className='bg-slate-600 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600'>
				Break the world
			</button>
		</div>
	);
};

export default Report;
