/** @format */

import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import SignInModal from './SignInModal';
import ktx_logo from '../assets/ktx_logo.png';

const navigation = [
	{ name: 'Blog', href: '#' },
	{ name: 'Pricing', href: '#' },
	{ name: 'Products', href: '#' },
	{ name: 'Company', href: '#' },
];

const HeaderHome: React.FC = () => {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [showModal, setShowModal] = useState(false);
	return (
		<header className='bg-white'>
			<nav
				className='mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8'
				aria-label='Global'>
				<div className='flex lg:flex-1'>
					<a href='#' className='-m-1.5 p-1.5'>
						<span className='sr-only'>Kortex.ai Logo</span>
						<img
							className='h-10 w-auto'
							src={ktx_logo}
							alt='Kortex.ai'
						/>
					</a>
				</div>

				<div className='flex lg:hidden'>
					<button
						type='button'
						className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-800'
						onClick={() => setMobileMenuOpen(true)}>
						<span className='sr-only'>Open main menu</span>
						<Bars3Icon className='h-6 w-6' aria-hidden='true' />
					</button>
				</div>

				<div className='hidden lg:flex lg:gap-x-12'>
					{navigation.map((item) => (
						<a
							key={item.name}
							href={item.href}
							className='text-sm font-semibold leading-6 text-gray-600'>
							{item.name}
						</a>
					))}
				</div>

				<div className='hidden lg:flex lg:flex-1 lg:justify-end'>
					{/* <button
						type='button'
						onClick={() => {
							setShowModal(true);
						}}
						className='text-sm font-semibold leading-6 text-white'>
						Log in <span aria-hidden='true'>&rarr;</span>
					</button> */}
					<Link
						to='/signin'
						className='text-sm font-semibold leading-6 text-gray-600'>
						Log in <span aria-hidden='true'>&rarr;</span>
					</Link>
				</div>
			</nav>

			<Dialog
				as='div'
				className='lg:hidden'
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}>
				<div className='fixed inset-0 z-10' />
				<Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10'>
					<div className='flex items-center justify-between'>
						<a href='#' className='-m-1.5 p-1.5'>
							<span className='sr-only'>Kortex.ai Logo</span>
							<img
								className='h-8 w-auto'
								src={ktx_logo}
								alt='kortex.ai logo'
							/>
						</a>
						<button
							type='button'
							className='-m-2.5 rounded-md p-2.5 text-gray-400'
							onClick={() => setMobileMenuOpen(false)}>
							<span className='sr-only'>Close menu</span>
							<XMarkIcon className='h-6 w-6' aria-hidden='true' />
						</button>
					</div>
					<div className='mt-6 flow-root'>
						<div className='-my-6 divide-y divide-gray-500/25'>
							<div className='space-y-2 py-6'>
								{navigation.map((item) => (
									<a
										key={item.name}
										href={item.href}
										className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-700 hover:bg-gray-200'>
										{item.name}
									</a>
								))}
							</div>
							<div className='py-6'>
								<Link
									to='/signin'
									className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-slate-600 hover:bg-gray-200'>
									Log in
								</Link>
							</div>
						</div>
					</div>
				</Dialog.Panel>
			</Dialog>
			{showModal && <SignInModal setOpenModal={setShowModal} />}
		</header>
	);
};

export default HeaderHome;
