/** @format */
import { useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from './UseAuthStore';
import HomeLanding from '../pages/HomeLanding';

interface AuthRequiredProps {
	children?: React.ReactNode;
}

export const AuthRequired = ({ children }: AuthRequiredProps) => {
	// Does it still work if accessToken is expired?
	const token = useAuthStore((state) => state.accessToken);
	const location = useLocation();

	if (!token) {
		return <Navigate to='/' replace state={{ from: location }} />;
	}

	return children ? <>{children}</> : <Outlet />;
};
