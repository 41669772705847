/** @format */

import { create } from 'zustand';
import { Note } from '../pages/Note';
import { deleteNoteByID, updateNoteFieldById } from './Services';

// TODO: Clean up note store, using react or zustand for notes?
interface NoteState {
	notes: Note[];
	setNotes: (notes: Note[]) => void;
	toggleArchiveForNote: (noteId: string, status: string) => Promise<void>;
	deleteNote: (noteId: string) => Promise<void>;
	activeNoteId: string | null;
	setActiveNoteId: (id: string | null) => void;
	addNote: (note: Note) => void;
	updateNote: (updatedNote: Note) => void;
}

const useNoteStore = create<NoteState>((set) => ({
	notes: [],
	setNotes: (notes) => set({ notes }),
	activeNoteId: null,
	setActiveNoteId: (id) => set({ activeNoteId: id }),
	addNote: (note) => set((state) => ({ notes: [...state.notes, note] })),

	// Action to update a note from autosave
	updateNote: (updatedNote) =>
		set((state) => {
			const updatedNotes = state.notes.map((note) =>
				note.id === updatedNote.id ? updatedNote : note
			);
			return { notes: updatedNotes };
		}),

	// Action to toggle the archive status of a note
	toggleArchiveForNote: async (noteId, status) => {
		try {
			// Find the current status of the note
			const newStatus = status === 'ARCHIVED' ? 'DRAFT' : 'ARCHIVED';

			// Perform the async operation to update the note status
			await updateNoteFieldById('notes', noteId.toString(), {
				status: newStatus,
			});

			// Then update the state
			set((state) => {
				const newNotes = state.notes.map((note) => {
					if (note.id === noteId) {
						return { ...note, status: newStatus };
					}
					return note;
				});

				return { notes: newNotes };
			});
		} catch (error) {
			console.error('Failed to toggle note status:', error);
			// Handle error appropriately
		}
	},

	// Action to delete a note
	deleteNote: async (noteId) => {
		// Confirm with the user
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this note?'
		);
		if (!confirmDelete) {
			// If the user clicked Cancel, don't delete the note
			return;
		}

		try {
			// Delete the note by ID (async operation)
			await deleteNoteByID('notes', noteId);

			// Then update the state to remove the note
			set((state) => ({
				notes: state.notes.filter((note) => note.id !== noteId),
			}));
		} catch (error) {
			console.error('Failed to delete note:', error);
			// Handle error appropriately
		}
	},
}));

export default useNoteStore;
