/** @format */

// React imports
import { useEffect, useRef, useState } from 'react';

// Third-party library imports
import { debounce } from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { useOutletContext, useParams } from 'react-router-dom';
import { PaperClipIcon } from '@heroicons/react/20/solid';

// Local imports
// TODO: refactor updateNOteFieldById to notestore?
import { updateNoteFieldById } from '../utils/Services';
import NoteDropdown from '../components/NoteDropdown';
import EditableDiv from '../components/EditableDiv';
import NoteSection from '../components/NoteSection';
import clsx from 'clsx';
import {
	ArchiveBoxIcon,
	ClipboardDocumentListIcon,
	PencilIcon,
} from '@heroicons/react/24/outline';
import { formatCreationTime } from '../utils/UtilFuncs';
import NoteSectionPending from '../components/NoteSectionPending';

// Define the Cache duration in milliseconds
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
// Consider adding in a hash for cache

export interface Note {
	[key: string]: string | number;
}

interface OutletContextType {
	handleDeleteNote: Function;
	handleArchiveNote: Function;
}

import useNoteStore from '../utils/NoteStore';

const Note: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isFading, setIsFading] = useState(false);
	const [isNoteMenuOpen, setIsNoteMenuOpen] = useState(false);
	const { notes, updateNote } = useNoteStore();

	const { handleDeleteNote, handleArchiveNote }: OutletContextType =
		useOutletContext();

	const { noteId = '', folder = '' } = useParams<{
		folder: string;
		noteId: string;
	}>();

	const note = notes.find((note: Note) => note.id === noteId) as Note;

	// console.log('note', note);
	// console.log('noteId', noteId);

	const handleCopy = () => {
		const contentHpi = contentHpiRef.current?.textContent || '';
		navigator.clipboard.writeText(contentHpi.toString() || '');
		console.log('copied: ', contentHpi.toString());
		toast.success('Copied!', {
			duration: 1000,
			position: 'top-center',
		});
	};

	const handleRewrite = () => {
		const contentHpi = contentHpiRef.current?.textContent || '';
		console.log('text to rewrite: ', contentHpi.toString());
		toast.success('Rewrite!', {
			duration: 1000,
			position: 'top-center',
		});
	};

	const handleToggleNoteMenu = () => {
		setIsNoteMenuOpen(!isNoteMenuOpen);
	};

	const handleToggleData = () => {
		setIsOpen(!isOpen);
	};

	//TODO: Update the autosavefield to use NoteStore, handleblur should also just call autosave
	const autoSaveField = (field: string, data: { [key: string]: string }) => {
		if (note.status === 'UNREVIEWED') {
			data['status'] = 'DRAFT';
			note.status = 'DRAFT';
		}
		console.log(`autoSave:${field} called with data:`, data);
		note[field] = data[field] || '';
		updateNoteFieldById('notes', note.id.toString(), data);
	};

	//TODO: Update references to be more abstract, robust, to handle any notesections needing monitoring for autosave
	const contentHpiRef = useRef<HTMLDivElement>(null);
	const contentComplaintRef = useRef<HTMLDivElement>(null);
	const contentCommentsRef = useRef<HTMLDivElement>(null);

	const fields = ['tx', 'complaint', 'comments'];
	const refs = [contentHpiRef, contentComplaintRef, contentCommentsRef];

	const debouncedCallApi = fields.map((field, index) =>
		debounce(
			(content: string) => autoSaveField(field, { [field]: content }),
			2000
		)
	);

	useEffect(() => {
		const handlers = fields.map((field, index) => {
			const currentElement = refs[index].current;

			if (currentElement) {
				const handleContentChange = () => {
					const content = currentElement.innerText;
					debouncedCallApi[index](content);
				};

				currentElement.addEventListener('input', handleContentChange);

				// Return a cleanup function for this field
				return () => {
					currentElement.removeEventListener(
						'input',
						handleContentChange
					);
					debouncedCallApi[index].cancel();
				};
			}

			return null;
		});

		// Return a cleanup function that cleans up all fields
		return () => {
			handlers.forEach((cleanup) => cleanup && cleanup());
		};
	}, [debouncedCallApi]);

	const handleContentBlur = (field: string) => {
		const ref =
			field === 'tx'
				? contentHpiRef
				: field === 'complaint'
				? contentComplaintRef
				: contentCommentsRef;
		const content = ref.current?.innerText || '';

		// Check if content has changed
		if (note[field] !== content) {
			note[field] = content || '';
			autoSaveField(field, { [field]: content });
			updateNote(note);
			console.log('handleContentBlur:', field, 'with:', content);
		}
	};

	const archiveNote = async (noteId: string) => {
		setIsFading(true);
		setTimeout(() => {
			handleArchiveNote(noteId);
			setIsFading(false);
		}, 250);
	};

	const deleteNote = async (noteId: string) => {
		setIsFading(true);
		setTimeout(() => {
			handleDeleteNote(noteId);
			setIsFading(false);
		}, 250);
	};

	const dropdownItems = [
		{
			title: isOpen ? 'Hide Data' : 'Show Data',
			href: '#',
			onClick: handleToggleData,
		},
		{
			title: 'Delete Note',
			href: '#',
			onClick: () => deleteNote(note.id.toString()),
		},
	];

	const hpiButtonData = [
		{
			key: 'rewrite',
			onClick: () => handleRewrite(),
			icon: PencilIcon,
			text: 'Rewrite',
		},
		{
			key: 'copy',
			onClick: () => handleCopy(),
			icon: ClipboardDocumentListIcon,
			text: 'Copy',
		},
		{
			key: 'archive',
			onClick: () => archiveNote(note.id.toString()),
			icon: ArchiveBoxIcon,
			text: note.status === 'ARCHIVED' ? 'Unarchive' : 'Archive',
		},
	];

	const white_button = `align-middle select-none text-center uppercase 
    transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none 
    text-xs py-2 px-3 mr-1 mobile:mr-2 mt-1 mobile:mt-2 rounded-lg text-black outline-1 border-2 border-gray-200 bg-white
    shadow-md shadow-gray-600/10 hover:shadow-lg hover:shadow-gray-900/20 hover:text-gray-700
    active:opacity-[0.85] flex items-center`;

	return (
		<div id='top-note-div'>
			<div
				id='note-editor-div'
				className={`border-2 shadow-xl rounded-xl transition-opacity duration-100 ${
					isFading ? 'opacity-0' : 'opacity-100'
				}`}>
				{/* Header */}
				<div
					id='note-editor-header'
					className='flex flex-row-reverse mx-2 mt-1'>
					<div
						className='flex items-center justify-center rounded-full focus:outline-none ml-1'
						onClick={handleToggleNoteMenu}>
						<NoteDropdown items={dropdownItems} />
					</div>
					<Toaster />
					{hpiButtonData.map(({ key, onClick, icon: Icon, text }) => (
						<button
							key={key}
							className={clsx(white_button, '')}
							type='button'
							onClick={onClick}>
							<Icon className='w-4 h-4 mr-1' />
							<span className='hidden sm:block'>{text}</span>
						</button>
					))}
				</div>
				{/* Body */}
				<NoteSection title='complaint'>
					<EditableDiv
						id={note.id.toString() + 'complaint'}
						className='text-base outline-none'
						onBlur={() => handleContentBlur('complaint')}
						ref={contentComplaintRef}
						content={note.complaint}
						focusOutline={true}
					/>
				</NoteSection>
				<NoteSection title='Hx'>
					<EditableDiv
						id={note.id.toString() + 'tx'}
						onBlur={() => handleContentBlur('tx')}
						ref={contentHpiRef}
						content={note.tx.toString()}
						focusOutline={true}
					/>
				</NoteSection>
				<NoteSection title='Pe'>
					<NoteSectionPending />
				</NoteSection>
				<NoteSection title='MDM'>
					<NoteSectionPending />
				</NoteSection>
				<NoteSection title='ED'>
					<NoteSectionPending />
				</NoteSection>
				<NoteSection title='comments'>
					{' '}
					<EditableDiv
						id={note.id.toString() + 'comments'}
						className='text-xs'
						onBlur={() => handleContentBlur('comments')}
						ref={contentCommentsRef}
						content={note.comments}
						focusOutline={true}
					/>{' '}
				</NoteSection>
				{/* Footer */}
				<div className='px-4 py-3 mx-2 text-xs flex flex-col sm:px-6 mt-3 border-t'>
					Note created: {formatCreationTime(new Date(note.created))}
				</div>
				{/* Data */}
				<div
					id='note-data-div'
					className='mt-10 overflow-hidden bg-white shadow-xl sm:rounded-lg'>
					{isOpen && (
						<div>
							<div className='bg-slate-700 flex items-center px-4 py-5 sm:px-6'>
								<h3 className='text-base font-semibold leading-6 text-white'>
									Data
								</h3>
							</div>

							<div className='border-t border-gray-200'>
								<dl>
									<div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
										<dt className='text-sm font-medium text-gray-500'>
											Audio Transcription
										</dt>
										<dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
											{note.audio_transcription}
										</dd>
									</div>
									<div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
										<dt className='text-sm font-medium text-gray-500'>
											Attachments
										</dt>
										<dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>
											<ul
												role='list'
												className='divide-y divide-gray-200 rounded-md border border-gray-200'>
												<li className='flex items-center justify-between py-3 pl-3 pr-4 text-sm'>
													<div className='flex w-0 flex-1 items-center'>
														<PaperClipIcon
															className='h-5 w-5 flex-shrink-0 text-gray-400'
															aria-hidden='true'
														/>
														<a
															href={note.audio.toString()}
															className='ml-2 font-medium text-indigo-600 hover:text-indigo-500'>
															Download Audio
														</a>
													</div>
												</li>
											</ul>
										</dd>
									</div>
								</dl>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Note;
