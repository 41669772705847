/** @format */

import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import {
	CogIcon,
	CheckIcon as TextStatusIcon,
} from '@heroicons/react/24/outline';
import { formatRelativeTime, formatMilitaryTime } from '../utils/UtilFuncs';
import { Note } from '../utils/Types';

interface ListItemProps {
	note: Note;
	onSmallScreenSelect?: () => void;
}

const listItemStyle = `select-none text-center uppercase 
    transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none 
    text-xs py-2 px-3 m-2 rounded-lg text-black outline-1 border-2 border-gray-200 bg-white
    shadow-md shadow-gray-600/10 hover:shadow-lg hover:shadow-gray-900/20 hover:text-gray-700
    active:opacity-[0.85] flex items-center gap-3`;

const ListItem: React.FC<ListItemProps> = ({ note, onSmallScreenSelect }) => {
	const itemClass = clsx(
		'rounded-lg border-2 border-slate-200 focus:outline-none focus:border-0',
		{
			'bg-white shadow-gray-600/10 shadow-md hover:shadow-lg focus:shadow-none':
				note.tx,
			'bg-zinc-50 opacity-50 shadow-none pointer-events-none': !note.tx,
		}
	);

	const content = (
		<div
			className={clsx(
				'flex items-center justify-between p-2 min-w-[150px]',
				{ 'animate-pulse': !note.tx }
			)}>
			<span
				className='text-sm font-medium mr-2 max-w-[150px] md:max-w-[200px] xlg:max-w-[250px] truncate'
				title={`${note.complaint}`}>
				{note.complaint ? note.complaint : 'Note Processing'}
			</span>
			<div>
				<svg className='animate-spin h-4 w-4' viewBox='0 0 24 24'>
					<CogIcon
						className={clsx('h-4 w-4 text-slate-700', {
							invisible: note.tx,
						})}
					/>
				</svg>
			</div>
		</div>
	);

	return (
		<li className={itemClass}>
			{note.tx ? (
				<NavLink
					to={`${note.id}`}
					className={({ isActive }) =>
						clsx('focus:outline-blue-500', {
							'text-blue-400': isActive,
							'block text-slate-800': !isActive,
						})
					}
					onClick={() => onSmallScreenSelect?.()}>
					{content}
				</NavLink>
			) : (
				content
			)}
		</li>
	);
};

export default ListItem;
